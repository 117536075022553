// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500i');

// Variables
@import "variables";

@import "stab";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  color: $text-color;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  list-style: none;
  box-sizing: border-box;
  tap-highlight-color: rgba(0,0,0,0);
  outline: 0;
  appearance: none;
}

a{
  text-decoration: none;
}

#header{
  background-color: $primary-color;
  background-position: center bottom -7px;
  background-repeat: no-repeat;
  background-image: url("/images/header-background.svg");
  background-size: auto 210px;
  height: 320px;
  transition: 0.4s;

  @media (max-width: $screen-x-max){
    background-size: auto 150px;
    height: 230px;
  }
}

#main{
  padding: 90px 0;

  .text-block{
    margin-bottom: 40px;
    text-align: center;

    a{
      font-weight: 500;
      font-style: italic;
    }
  }

  .form-content{
    border: 1px solid $default-border-color;
    border-radius: $default-border-radius;
    padding: 0 10px 10px 10px;

    input[type=text]{
      display: block;
      border: 1px solid $default-border-color;
      border-radius: $default-border-radius;
      padding: 0 15px;
      width: 100%;
      height: 44px;
    }

    textarea{
      display: block;
      border: 1px solid $default-border-color;
      border-radius: $default-border-radius;
      padding: 10px 15px;
      width: 100%;
      height: 88px;
      resize: vertical;
    }

    .first-section{
      .input{
        position: relative;
        margin-top: 10px;

        &:before{
          display: inline-block;
          content: "@";
          position: absolute;
          top: 0;
          left: 10px;
          color: #a9a9a9;
          font-size: 20px;
          line-height: 42px;
        }

        input{
          padding: 0 15px 0 30px;
        }
      }

      .users-list{
        @include clear-fix();

        .user{
          display: none;
          opacity: 0;

          .user-content{
          display: table;
          float: left;
          margin-top: 10px;
          border: 1px solid $default-border-color;
          border-radius: $default-border-radius;
          width: calc(50% - 5px);
          transition: opacity 0.4s, background-color 0.4s;

            .image{
              display: table-cell;
              padding: 5px;
              width: 58px;
              vertical-align: middle;

              img{
                display: block;
                border-radius: $default-border-radius - 1;
              }
            }

            .infos{
              display: table-cell;
              padding: 5px;
              vertical-align: middle;

              .name{}

              .screen-name{
                color: rgba($text-color, 0.5);
                font-size: 12px;
              }
            }
          }

          &:nth-child(odd){
            .user-content{
              margin-right: 10px;
            }
          }

          &:only-child{
            .user-content{
              margin-right: 0;
              width: 100%;
            }
          }

          &.selected{
            .user-content{
              background-color: rgba($default-border-color, 0.3);
              opacity: 1;
            }
          }
        }

        &:hover{
          .user{
            .user-content{
              opacity: 0.4;

              &:hover{
                background-color: rgba($default-border-color, 0.3);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .second-section{
      display: none;
      margin-top: 10px;
      opacity: 0;

      .message-length{
        margin-top: 5px;
        color: lighten($text-color, 50%);
        font-size: 10px;
        text-align: right;

        span{
          font-size: 10px;
          transition: 0.4s;

          &.red{
            color: red;
          }
        }
      }

      input[type=submit]{
        display: block;
        background-color: $primary-color;
        margin-top: 10px;
        border-radius: $default-border-radius;
        width: 100%;
        height: 44px;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.4s, box-shadow 0.4s;

        &:hover{
          background-color: darken($primary-color, 5%);
          box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
        }
      }
    }
  }
}