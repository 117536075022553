$columns: 12;

$default-margin: 15px;
$screen-m-max: 1200px;
$screen-s-max: 992px;
$screen-x-max: 768px;

$container-l-max: 1170px;
$container-m-max: 970px;
$container-s-max: 750px;
$container-x-max: 100%;

@mixin clear-fix {
  &:before, &:after{
    content: "";
    display: block;
    clear: both;
  }
}

@mixin margin($value) {
  margin-right: $value;
  margin-left: $value;
}

@mixin padding($value) {
  padding-right: $value;
  padding-left: $value;
}

@mixin s-collumn-list {
  @for $i from 1 through $columns {
    .s-l-#{$i}, .s-m-#{$i}, .s-s-#{$i}, .s-x-#{$i} { @extend %s-collumn-list-style; }
  }
}

.s-cont {
  @include margin(auto);
  @include padding(15px);
  width: $container-l-max;

  @include clear-fix;
}

.s-row {
  @include margin(-15px);

  @include clear-fix;
}

@media (max-width: $screen-m-max) {
  .s-cont {
    width: $container-m-max;
  }
}

@media (max-width: $screen-s-max) {
  .s-cont {
    width: $container-s-max;
  }
}

@media (max-width: $screen-x-max) {
  .s-cont {
    width: $container-x-max;
  }
}

%s-collumn-list-style {
  position: relative;
  float: left;
  @include padding(15px);
  min-height: 1px;
}

@include s-collumn-list;

@for $i from 1 through $columns {
  .s-l-#{$i} {
    width: ((100 / $columns) * $i) * 1%;
  }
}

@media (max-width: $screen-m-max) {
  @for $i from 1 through $columns {
    .s-m-#{$i} {
      width: ((100 / $columns) * $i) * 1%;
    }
  }
}

@media (max-width: $screen-s-max) {
  @for $i from 1 through $columns {
    .s-s-#{$i} {
      width: ((100 / $columns) * $i) * 1%;
    }
  }
}

@media (max-width: $screen-x-max) {
  @for $i from 1 through $columns {
    .s-x-#{$i} {
      width: ((100 / $columns) * $i) * 1%;
    }
  }
}

.s-center{
  float: none;
  margin: 0 auto;
}