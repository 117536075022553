@import url("https://fonts.googleapis.com/css?family=Roboto:400,500i");
.s-cont {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 1170px;
}

.s-cont:before, .s-cont:after {
  content: "";
  display: block;
  clear: both;
}

.s-row {
  margin-right: -15px;
  margin-left: -15px;
}

.s-row:before, .s-row:after {
  content: "";
  display: block;
  clear: both;
}

@media (max-width: 1200px) {
  .s-cont {
    width: 970px;
  }
}

@media (max-width: 992px) {
  .s-cont {
    width: 750px;
  }
}

@media (max-width: 768px) {
  .s-cont {
    width: 100%;
  }
}

.s-l-1, .s-m-1, .s-s-1, .s-x-1, .s-l-2, .s-m-2, .s-s-2, .s-x-2, .s-l-3, .s-m-3, .s-s-3, .s-x-3, .s-l-4, .s-m-4, .s-s-4, .s-x-4, .s-l-5, .s-m-5, .s-s-5, .s-x-5, .s-l-6, .s-m-6, .s-s-6, .s-x-6, .s-l-7, .s-m-7, .s-s-7, .s-x-7, .s-l-8, .s-m-8, .s-s-8, .s-x-8, .s-l-9, .s-m-9, .s-s-9, .s-x-9, .s-l-10, .s-m-10, .s-s-10, .s-x-10, .s-l-11, .s-m-11, .s-s-11, .s-x-11, .s-l-12, .s-m-12, .s-s-12, .s-x-12 {
  position: relative;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 1px;
}

.s-l-1 {
  width: 8.3333333333%;
}

.s-l-2 {
  width: 16.6666666667%;
}

.s-l-3 {
  width: 25%;
}

.s-l-4 {
  width: 33.3333333333%;
}

.s-l-5 {
  width: 41.6666666667%;
}

.s-l-6 {
  width: 50%;
}

.s-l-7 {
  width: 58.3333333333%;
}

.s-l-8 {
  width: 66.6666666667%;
}

.s-l-9 {
  width: 75%;
}

.s-l-10 {
  width: 83.3333333333%;
}

.s-l-11 {
  width: 91.6666666667%;
}

.s-l-12 {
  width: 100%;
}

@media (max-width: 1200px) {
  .s-m-1 {
    width: 8.3333333333%;
  }
  .s-m-2 {
    width: 16.6666666667%;
  }
  .s-m-3 {
    width: 25%;
  }
  .s-m-4 {
    width: 33.3333333333%;
  }
  .s-m-5 {
    width: 41.6666666667%;
  }
  .s-m-6 {
    width: 50%;
  }
  .s-m-7 {
    width: 58.3333333333%;
  }
  .s-m-8 {
    width: 66.6666666667%;
  }
  .s-m-9 {
    width: 75%;
  }
  .s-m-10 {
    width: 83.3333333333%;
  }
  .s-m-11 {
    width: 91.6666666667%;
  }
  .s-m-12 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .s-s-1 {
    width: 8.3333333333%;
  }
  .s-s-2 {
    width: 16.6666666667%;
  }
  .s-s-3 {
    width: 25%;
  }
  .s-s-4 {
    width: 33.3333333333%;
  }
  .s-s-5 {
    width: 41.6666666667%;
  }
  .s-s-6 {
    width: 50%;
  }
  .s-s-7 {
    width: 58.3333333333%;
  }
  .s-s-8 {
    width: 66.6666666667%;
  }
  .s-s-9 {
    width: 75%;
  }
  .s-s-10 {
    width: 83.3333333333%;
  }
  .s-s-11 {
    width: 91.6666666667%;
  }
  .s-s-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .s-x-1 {
    width: 8.3333333333%;
  }
  .s-x-2 {
    width: 16.6666666667%;
  }
  .s-x-3 {
    width: 25%;
  }
  .s-x-4 {
    width: 33.3333333333%;
  }
  .s-x-5 {
    width: 41.6666666667%;
  }
  .s-x-6 {
    width: 50%;
  }
  .s-x-7 {
    width: 58.3333333333%;
  }
  .s-x-8 {
    width: 66.6666666667%;
  }
  .s-x-9 {
    width: 75%;
  }
  .s-x-10 {
    width: 83.3333333333%;
  }
  .s-x-11 {
    width: 91.6666666667%;
  }
  .s-x-12 {
    width: 100%;
  }
}

.s-center {
  float: none;
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  color: #242424;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  list-style: none;
  box-sizing: border-box;
  tap-highlight-color: transparent;
  outline: 0;
  appearance: none;
}

a {
  text-decoration: none;
}

#header {
  background-color: #4b6fb9;
  background-position: center bottom -7px;
  background-repeat: no-repeat;
  background-image: url("/images/header-background.svg");
  background-size: auto 210px;
  height: 320px;
  transition: 0.4s;
}

@media (max-width: 768px) {
  #header {
    background-size: auto 150px;
    height: 230px;
  }
}

#main {
  padding: 90px 0;
}

#main .text-block {
  margin-bottom: 40px;
  text-align: center;
}

#main .text-block a {
  font-weight: 500;
  font-style: italic;
}

#main .form-content {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 10px 10px 10px;
}

#main .form-content input[type=text] {
  display: block;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 0 15px;
  width: 100%;
  height: 44px;
}

#main .form-content textarea {
  display: block;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
  height: 88px;
  resize: vertical;
}

#main .form-content .first-section .input {
  position: relative;
  margin-top: 10px;
}

#main .form-content .first-section .input:before {
  display: inline-block;
  content: "@";
  position: absolute;
  top: 0;
  left: 10px;
  color: #a9a9a9;
  font-size: 20px;
  line-height: 42px;
}

#main .form-content .first-section .input input {
  padding: 0 15px 0 30px;
}

#main .form-content .first-section .users-list:before, #main .form-content .first-section .users-list:after {
  content: "";
  display: block;
  clear: both;
}

#main .form-content .first-section .users-list .user {
  display: none;
  opacity: 0;
}

#main .form-content .first-section .users-list .user .user-content {
  display: table;
  float: left;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: calc(50% - 5px);
  transition: opacity 0.4s, background-color 0.4s;
}

#main .form-content .first-section .users-list .user .user-content .image {
  display: table-cell;
  padding: 5px;
  width: 58px;
  vertical-align: middle;
}

#main .form-content .first-section .users-list .user .user-content .image img {
  display: block;
  border-radius: 3px;
}

#main .form-content .first-section .users-list .user .user-content .infos {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
}

#main .form-content .first-section .users-list .user .user-content .infos .screen-name {
  color: rgba(36, 36, 36, 0.5);
  font-size: 12px;
}

#main .form-content .first-section .users-list .user:nth-child(odd) .user-content {
  margin-right: 10px;
}

#main .form-content .first-section .users-list .user:only-child .user-content {
  margin-right: 0;
  width: 100%;
}

#main .form-content .first-section .users-list .user.selected .user-content {
  background-color: rgba(230, 230, 230, 0.3);
  opacity: 1;
}

#main .form-content .first-section .users-list:hover .user .user-content {
  opacity: 0.4;
}

#main .form-content .first-section .users-list:hover .user .user-content:hover {
  background-color: rgba(230, 230, 230, 0.3);
  opacity: 1;
}

#main .form-content .second-section {
  display: none;
  margin-top: 10px;
  opacity: 0;
}

#main .form-content .second-section .message-length {
  margin-top: 5px;
  color: #a4a4a4;
  font-size: 10px;
  text-align: right;
}

#main .form-content .second-section .message-length span {
  font-size: 10px;
  transition: 0.4s;
}

#main .form-content .second-section .message-length span.red {
  color: red;
}

#main .form-content .second-section input[type=submit] {
  display: block;
  background-color: #4b6fb9;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.4s, box-shadow 0.4s;
}

#main .form-content .second-section input[type=submit]:hover {
  background-color: #4263a9;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
}
